<template>
    <BlogPostLayout :tags="tags" :meta="meta">
        <template v-slot:title>Fastly: Pushing content closer to users</template>

        <template v-slot:excerpt>Superology leads the way when it comes to fast-paced technological
            innovation in the betting industry. Conventional systems are changing fast to accommodate millions
            of users demanding and consuming sports content constantly, even at 5 AM. Caching has become vital
            for content providers because end users have little tolerance for delays.</template>

        <template v-slot:content>
            <img src="../../../assets/blog/images/fastly.jpg" class="blog-post-image" alt="Fastly">

            <h2>The vision: To be small on the inside and big on the outside</h2>

            <p>Superology decided to use Fastly’s CDN Edge Cloud platform to speed up mobile applications by
                pushing content closer to its users, hence providing an upgraded user experience. Fastly’s
                global
                <a href="https://docs.fastly.com/en/guides/using-fastlys-global-pop-network"
                   target="_blank">content delivery capabilities</a> include caching services to accelerate
                the delivery of content and protect their origin servers, making the whole process faster,
                safer and smoother.
            </p>

            <p>The move to Fastly was driven by a need for high-performance and streamlined workflows,
                initially for the mobile betting app. Our engineers wanted to simplify computing every user
                request (requests can peak at 15,000 per second). Fastly’s cache purging from the inside
                helps manage the large volume of content requests coming in.</p>

            <p>The nature of live sports means that users have zero tolerance for time lags and blackouts.
                Fastly’s CDN provisioning at 130 Tbps with POPs strategically located and connected around
                the globe provides the needed performance and scalability. The origin load is kept at a
                minimum thanks to features such as <a
                    href="https://developer.fastly.com/learning/concepts/request-collapsing/"
                    target="_blank">request collapsing</a> and
                <a href="https://docs.fastly.com/en/guides/shielding" target="_blank">origin shielding</a>.
                Also, the
                ability to invalidate content in less than 150 milliseconds means that Superology can
                provide accurate information without delays.
            </p>

            <h2>Real-time metrics and speedy analysis</h2>

            <p>Real-time logs and statistics via the API provide Superology with the level of observability
                required to monitor all services and act instantly in case of trouble. In particular, new
                features require speedy analysis. And Fastly’s
                <a href="https://docs.fastly.com/en/guides/about-fastlys-realtime-log-streaming-features"
                   target="_blank">real-time log streaming</a> integration to Amazon’s S3 helps the
                engineering team monitor and track all the APIs and run fast analytics on large amounts of
                data. The team found it easy to integrate S3 logs with an in-house analytics solution backed
                up by Clickhouse.</p>

            <blockquote class="quote-solid-line">
                <p class="bold-quote">Delivered at the edge: World’s first sports fan social app for the
                    betting industry</p>
            </blockquote>

            <h2>Authentication on the edge</h2>

            <p>Thanks to programmable VCL, Superology can authenticate users on the edge by verifying <a
                href="https://developer.fastly.com/solutions/examples/json-web-tokens" target="_blank">JWT
                tokens</a>, so any request with an invalid or expired token will never reach origin servers.
                That helps the engineering team not to worry about potential high traffic of malicious
                users.</p>

            <h2>Caching streaming content</h2>

            <p>Fastly’s streaming miss technology enables Superology to cache streaming content. Chats and
                live match trackers are delivered by
                <a href="https://www.fastly.com/blog/server-sent-events-fastly" target="_blank">server-sent
                    events (SSE)</a>, ensuring that only one request per resource reaches the origin
                servers, regardless of thousands of users watching.</p>

            <h2>Microservices and Fastly</h2>

            <p>Superology’s microservices architecture helps deal with cache invalidation. When the content
                changes, for example, a user changes a profile picture or comments in a chat, the
                corresponding microservice issues a command to purge the Fastly cache, which immediately
                updates the whole stack. By collecting the purge requests from all applications and having
                APIs per project, Superology gained the flexibility to do a fast lane purge from one place
                or spread the purges.
            </p>

            <blockquote class="quote-solid-line">
                <p class="bold-quote">Developers empowered by a self-service approach</p>
            </blockquote>

            <h2>Easy integration and support</h2>

            <p>Prior Varnish knowledge made integration easy for Superology. The familiar terminology and
                Fastly’s articles written for developers rather than salespeople also enabled a painless
                migration. When new features are developed, the DevOps team transforms them into Terraform
                script, so delivering changes is reproducible and transparent across all environments.</p>

            <h2>Driving change</h2>

            <p>Superbet acquired Superology to lead the digital transformation of its products and build a
                culture of innovation on the group level. With the help of Fastly, Superology brings betting
                apps into line with the most popular social media apps and helps grow a thriving community
                within the industry.</p>
        </template>
    </BlogPostLayout>
</template>

<script>

import blogMetadata from '@/util/blogMetadata'
import BlogPostLayout from '@/components/blog/BlogPostLayout.vue'
import authors from '@/views/blog/posts/authors'

export default {
    name: 'FastlyPushingContentCloserToUsers',

    metaInfo: blogMetadata.fastlyPushingContentCLoserToUsers,

    props: {},

    data: function () {
        return {
            tags: ['Platform', 'Fastly', 'Backend'],
            meta: {
                authors: [authors.petarKujundzic],
                publishedDate: '07 nov 2022.'
            }
        }
    },

    created() {

    },

    components: {BlogPostLayout},

    computed: {},

    methods: {}
}
</script>
